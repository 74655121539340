import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/Seo"

import MenuContent from "../components/MenuContent"

const IndexPage = () => (
  <Layout wide>
    <SEO title="Menu restauracyjne" />
    <MenuContent />
  </Layout>
)

export default IndexPage
